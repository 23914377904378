import { gql } from '@apollo/client';
import { FetchAddressInterface } from 'modules/addresses/interfaces';
import { useAsyncOperation } from 'modules/common/hooks';
import { requestGql } from 'modules/common/utils/request-gql';
import { useEffect } from 'react';
import { OrderTypeEnum } from 'views/orders/enums';

interface FetchAddressesArgs {
  ids?: string[];
  kunnr: string;
  orderTypes?: OrderTypeEnum[];
  contractId?: string;
  productId?: string;
}

const fetchAddresses = async (args: FetchAddressesArgs): Promise<FetchAddressInterface> =>
  requestGql(
    {
      query: gql`
        query addresses($filter: AddressFilterArgType) {
          addresses(filter: $filter) {
            data {
              id
              displayAddress
            }
            totalCount
          }
        }
      `,
      variables: { filter: args },
    },
    null,
    'addresses',
  );

export const useAddressSearch = (args: FetchAddressesArgs,
  options?: { initiateFetch: boolean }) => {
  const initiateFetch = options?.initiateFetch || true;

  const { isLoading, initiateOperation, status } = useAsyncOperation({ callback: fetchAddresses });
  const result = status?.result?.data || [];

  useEffect(() => {
    if (!!args?.kunnr && !!initiateFetch) {
      void initiateOperation(args);
    }
  }, [args?.kunnr, initiateFetch]);


  return { isLoading, data: result };
};
